/* eslint-disable */
/* stylelint-disable */
// @ts-nocheck

import { connect } from 'react-redux';
import { PureComponent } from 'react';
import ProductFbtDispatcher from 'Store/ProductFbt/ProductFbt.dispatcher';
import ProductFbt from './ProductFbt.component';

/** @namespace Component/Productfbt/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isAdding: state.ProductFbtReducer.isAdding,
});

/** @namespace Component/Productfbt/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addToCart: (product) => ProductFbtDispatcher.addToCart(dispatch, product),
});

/** @namespace Component/ProductFbt/Container */
export class ProductFbtContainer extends PureComponent {

    containerFunctions = {
        addToCart: this.addToCart.bind(this)
    };

    addToCart() {
        const { addToCart, data: product } = this.props
        addToCart(product)
    }

    containerProps() {
        const {
            data: product,
            isAdding
        } = this.props;

        return {
            product,
            isAdding
        };
    }

    render() {
        return <ProductFbt
            { ...this.containerFunctions }
            { ...this.containerProps() }
        />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFbtContainer);
