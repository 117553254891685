/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
/* eslint-disable spaced-comment */

import { SliderComponent as SourceSliderComponent } from "SourceComponent/Slider/Slider.component";
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';

/**
 * Slider component
 * @class Slider
 * @namespace Component/Slider/Component
 */
export class SliderComponent extends SourceSliderComponent {
    goNext() {
        const { activeImage, children } = this.props;
        const nextImage = activeImage + 1;
        let childLength = 0;
        children.map(
            child => {
                if (Array.isArray(child)) {
                    childLength = child.length; 
                }
            }
        ) 
        
        // if (nextImage < children.length) {
        //     this.changeActiveImage(nextImage);
        // }
        if (nextImage < childLength) {
            this.changeActiveImage(nextImage);
        }
       
    }
    renderArrows() {
        const { showArrows, activeImage, children } = this.props;
        let childLength = 0;
        children.map(
            child => {
                if (Array.isArray(child)) {
                    childLength = child.length; 
                }
            }
        ) 
        // const nextIsDisabled = activeImage + 1 === children.length;
        const nextIsDisabled = activeImage + 1 === childLength;
        const prevIsDisabled = activeImage === 0;
        
        if (!showArrows) {
            return null;
        }
        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <ChevronIcon direction={ Directions.LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <ChevronIcon direction={ Directions.RIGHT } />
                </button>
            </>
        );
    }
}

export default SliderComponent;
