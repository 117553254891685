/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import {  withRouter } from 'react-router';
import {
    ProductGalleryComponent as SourceProductGalleryComponent,
} from "SourceComponent/ProductGallery/ProductGallery.component";

import './ProductGallery.override.style';

/**
 * Product gallery
 * @class ProductGallery
 * @namespace Component/ProductGallery/Component
 */
export class ProductGalleryComponent extends SourceProductGalleryComponent {
   
}

export default withRouter(ProductGalleryComponent);
