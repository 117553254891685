// @ts-nocheck
/* eslint-disable */

import {
    UPDATE_IS_ADDING_PRODUCT_FBT
} from './ProductFbt.type';

export const isAdding = (loading) => ({
    type: UPDATE_IS_ADDING_PRODUCT_FBT,
    isAdding: loading,
});