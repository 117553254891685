/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

// In JavaScript, we'll use an object to mimic the enum behavior
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */ 

export const NavigationTabsMap ={
    HOME_TAB : 'HOME_TAB',
    MENU_TAB : 'MENU_TAB',
    ACCOUNT_TAB : 'ACCOUNT_TAB',
    CART_TAB : 'CART_TAB',
    CHECKOUT_TAB : 'CHECKOUT_TAB',   
}
