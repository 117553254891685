// @ts-nocheck
/* eslint-disable */

import { fetchMutation } from 'Util/Request/Mutation';
import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { getErrorMessage } from 'Util/Request/Error';
import CartQuery from 'Query/Cart.query';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { getCartId } from 'Util/Cart';
import {
    isAdding
} from './ProductFbt.action';

/**
 * Product ProductFbt Dispatcher
 * @class ProductFbtDispatcher
 * @namespace Store/ProductFbt/Dispatcher
 */
export class ProductFbtDispatcher {
    async addToCart(
        dispatch,
        product
    ) {
        try {
            const cartId = getCartId();

            const fbtProducts = product?.fbt_products || [];

            if(fbtProducts.length == 0) {
                dispatch(showNotification(NotificationType.ERROR, __('No product data!')));
                return Promise.reject();
            }

            if (!cartId) {
                return await Promise.reject();
            }

            dispatch(isAdding(true));

            const fbtProductsParams = fbtProducts.map((fbtProduct) => {
                const { sku, quantity = 1 } = fbtProduct;

                return {
                    sku,
                    quantity,
                };

            })

            const { addProductsToCart: { user_errors: errors = [] } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartMutation(cartId, fbtProductsParams),
            );

            if (Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
                });

                return await Promise.resolve();
            }

            await CartDispatcher.updateInitialCartData(dispatch);
            dispatch(showNotification(NotificationType.SUCCESS, __('Product was added to cart!')));

            dispatch(isAdding(false));

        } catch (error) {

            if (!navigator.onLine) {
                dispatch(showNotification(NotificationType.ERROR, __('Not possible to fetch while offline')));
                return Promise.reject();
            }

            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
            dispatch(isAdding(false));

            return Promise.reject();
        }

        return Promise.resolve();
    }
}

export default new ProductFbtDispatcher();
