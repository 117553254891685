/* eslint-disable */
/* stylelint-disable */
// @ts-nocheck

import { PureComponent } from 'react';
import ProductCard from 'Component/ProductCard';
import ContentWrapper from 'Component/ContentWrapper';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';
import { formatPrice } from 'Util/Price';

import './ProductFbt.style';

export const AddToCart = lowPriorityLazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "product-misc" */ 'Component/AddToCart'),
);
/**
 * ProductFbt
 * @class ProductFbt
 /* @namespace Component/ProductFbt/Component */

export class ProductFbtComponent extends PureComponent {

    renderProductCard(product, i) {

        return (
            <ProductCard
                product={ product }
                key={ i }
            />
        );
    }

    renderFbtItems(fbtProducts){
        const totalProducts = fbtProducts.length - 1;
        return fbtProducts.map((product, i) => {
            return (
                <div key={i} block="ProductPage" elem="FbtItem">
                    {this.renderProductCard(product, i)}
                    {i < totalProducts && <div block="ProductPage" elem="FbtItemPlus"> { __('+') } </div>}
                </div>
            )
        });
    }

    getTotalPrice(fbtProducts) {
        return fbtProducts.reduce((acc, fbtProduct) => {
            const minPrice = fbtProduct.special_price ? fbtProduct.special_price : fbtProduct.price_range?.minimum_price?.final_price?.value;
            return minPrice ? acc + minPrice : acc;
        }, 0);
    }

    getCurrencyCode(fbtProducts) {
        return fbtProducts[0].price_range?.minimum_price?.final_price?.currency || 'USD';
    }
 
    render() {
        const { product, addToCart, isAdding } = this.props;

        const fbtProducts = product?.fbt_products || [];

        if (!fbtProducts.length) {
            return null;
        }
        
        return (
            <div block="ProductPage" elem="BoughtWrapper">
                <ContentWrapper
                    wrapperMix={ { block: 'ProductPage', elem: 'BoughtContainer' } }
                >
                    <div block="page-section" elem="head">
                        <h2 block="page-section" elem="title">{ __('Frequently Bought Together') }</h2>
                    </div>
                    <div block="page-section" elem="body">
                        <div block="ProductPage" elem="FbtItems">
                            {this.renderFbtItems(fbtProducts)}
                        </div>
                        <div block="page-section" elem="actions">
                            <button
                                onClick={addToCart}
                                block="Button FbtAddToCart"
                                disabled={ isAdding }
                            >
                                {isAdding && <span>{__('Adding...')}</span>}
                                {!isAdding &&
                                    <>
                                        <span>{ __('Buy All') }&nbsp;{fbtProducts.length}:&nbsp;</span>
                                        <span>
                                            { formatPrice(this.getTotalPrice(fbtProducts), this.getCurrencyCode(fbtProducts)) }
                                        </span>
                                    </>
                                }
                            </button> 
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}
export default ProductFbtComponent;